.login-block {
    padding: 30px 0;
    margin: 0 auto;
    background: url(assets/img/auth/bg.jpg) no-repeat;
    background-size: cover;
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(1, 125, 172, 0.68);
        top: 0;
        left: 0;
    }
    .auth-box {
        margin: 20px auto 0 auto;
        max-width: 450px;
        .confirm {
            h3 {
                color: #01a9ac;
                font-size: 34px;
            }
        }
    }
    &.with-header {
        min-height: calc(100vh - 56px);
    }
}

@media only screen and (max-width: 767px) {
    .auth-box {
        margin: 0;
    }
    .login {
        h3 {
            margin-top: 0;
            margin-bottom: 0;
        }
        .m-b-20 {
            margin-bottom: 10px;
        }
        .m-t-30 {
            margin-top: 10px;
        }
    }
    .login-card,
    .signin-card {
        &.auth-body {
            width: 100%;
        }
    }
}
