html:not([dir='rtl']) {
    .sidebar {
        margin-left: -240px;
    }
}

.sidebar {
    background: $inverse;
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.13);
    .sidebar-nav {
        width: 100%;
    }
    .nav {
        width: 100%;
    }
}

.app-body {
    .sidebar {
        -webkit-flex: 0 0 240px;
        flex: 0 0 240px;
    }
}

@media (min-width: 992px) {
    .sidebar-fixed {
        .sidebar {
            width: 240px;
        }
    }
    html:not([dir='rtl']) {
        .sidebar-lg-show,
        .sidebar-show {
            &.sidebar-fixed {
                .main,
                .app-footer {
                    margin-left: 240px;
                }
            }
        }
    }
}

.app-header {
    padding: 0;
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.13);
    background: $white;
    color: $inverse;
    border-bottom: none;
    display: block;
    height: 56px;
    .logo-wrapper {
        position: relative;
        -ms-flex-align: center;
        align-items: center;
        display: -ms-flexbox;
        display: flex;
        text-align: center;
        text-transform: uppercase;
        width: 240px;
        padding: 8px 15px;
        background-color: $inverse;
        height: 100%;
        color: $white;
        float: left;
    }
    .navbar-brand {
        display: block;
        width: 100%;
        height: auto;
        padding-right: 30px;
        text-align: left;
        img {
            height: 40px;
            width: auto;
        }
    }
    .navbar-toggler {
        position: absolute;
        right: 5px;
        top: calc(50% - 20px);
        border-radius: 50%;
        padding: 5px 9px;
        font-size: 20px;
        min-width: 1px;
        color: $white;
        border: none;
        &:hover {
            .navbar-toggler-icon {
                background: none;
            }
        }
    }
    .navbar-toggler-icon {
        background: none;
        height: auto;
        width: auto;
        color: $white;
        &:before {
            content: '\e8d8';
            font-family: 'feather';
        }
    }
    .navbar-container {
        padding-left: 240px;
        >.container-fluid {
            display: flex;
            align-items: center;
            height: 56px;
            padding-left: 40px;
            padding-right: 40px;
        }
    }
    .navbar-nav {
        display: inline-flex;
        justify-content: flex-end;
        &.nav-right {
            margin-left: auto;
        }
        &.nav-left {
            margin-right: auto;
            .organization-name {
                display: inline-block;
                margin-right: 5px;
            }
        }
    }
}

.sidebar-show {
    .app-header {
        .navbar-toggler-icon {
            &:before {
                content: '\e8d9';
            }
        }
    }
}

@media (max-width: 991.98px) {
    .app-header {
        .navbar-brand {
            margin: 0;
            position: static;
        }
    }
    .sidebar {
        width: 240px;
    }
    // html:not([dir='rtl']) {
    //     .sidebar-lg-show {
    //         .sidebar {
    //             margin-left: 0;
    //         }
    //     }
    // }
}

.main {
    .container-fluid {
        padding: 10px;
    }
    .page-wrapper {
        padding: 30px;
        transition: all 0.3s ease-in;
        .page-header {
            margin-bottom: 30px;
            .add-new-item {
                text-align: right;
                @media (max-width: 768px) {
                    text-align: center;
                }
            }
            @media (max-width: 992px) {
                .add-new-item {
                    &.multiple {
                        text-align: center;
                    }
                }
            }
        }
        .page-header-title {
            @media (max-width: 768px) {
                display: block;
                margin-bottom: 20px;
                text-align: center;
            }
            &.multiple {
                @media (max-width: 992px) {
                    display: block;
                    margin-bottom: 20px;
                    text-align: center;
                }
            }
            h4 {
                display: block;
                margin-bottom: 0;
                font-weight: 600;
                color: #303548;
                font-size: 20px;
                text-transform: capitalize;
            }
            span {
                font-size: 13px;
                color: #919aa3;
                display: inline-block;
                margin-top: 10px;
            }
            i {
                display: -webkit-inline-box;
                display: -ms-inline-flexbox;
                display: inline-flex;
                float: left;
                width: 50px;
                height: 50px;
                border-radius: 4px;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                vertical-align: middle;
                font-size: 25px;
                color: #fff;
                -webkit-box-shadow: 0 2px 12px -3px rgba(0, 0, 0, 0.5);
                box-shadow: 0 2px 12px -3px rgba(0, 0, 0, 0.5);
                margin-right: 20px;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
            }
        }
    }
}

.navbar-nav {
    .nav-link {
        color: #404e67;
    }
}

.app-header {
    .dropdown-menu {
        background: #fff;
        box-shadow: 0 2px 30px 6px rgba(0, 0, 0, 0.1);
        position: absolute;
        transition: all 0.3s ease-in-out;
        right: -15px !important;
        top: 65px !important;
        left: auto !important;
        border: none;
        width: 15em;
        margin: 0;
        transform: none !important;
        &:after {
            content: '';
            position: absolute;
            top: -10px;
            right: 30px;
            transform: rotate(45deg);
            border: 10px solid #fff;
            border-right-color: transparent;
            border-bottom-color: transparent;
            box-shadow: -10px -9px 23px 1px rgba(0, 0, 0, 0.1);
        }
        .dropdown-item {
            line-height: normal;
            padding: 1em 20px;
            width: 100%;
            cursor: pointer;
            transition: all 0.5s ease-in-out;
            padding: 13px 20px;
            color: #666;
            background: none;
            border: none;
            display: block;
            line-height: 18px;
            i {
                font-size: 18px;
                margin-left: 0;
                margin-right: 10px;
                width: auto;
                color: #666;
                vertical-align: top;
                line-height: 1em;
            }
            &:hover {
                background-color: #f1f1f1;
            }
        }
    }
    .nav-item {
        .nav-link {
            position: relative;
            >.img-avatar {
                height: 30px;
                margin: 0 10px 0 0;
            }
        }
        &.dropdown {
            >.nav-link {
                padding-right: 20px;
                user-select: none;
                &:after {
                    font-family: 'feather';
                    content: '\e842';
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 10px;
                }
            }
        }
    }
}

@media (max-width:640px) {
    .app-header {
        height: auto;
        .logo-wrapper {
            display: block;
            width: 100%;
        }
        .navbar-container {
            padding-left: 0;
            background: $white;
            >.container-fluid {
                padding-left: 15px;
                padding-right: 15px;
                height: 50px;
            }
        }
        .dropdown-menu {
            right: 0 !important;
            .dropdown-item {
                padding: 12px 15px;
            }
        }
        .nav-item.dropdown {
            >.nav-link {
                padding-right: 15px;
            }
        }
    }
    .app-body {
        margin-top: 106px;
    }
    .sidebar {
        margin-top: -50px;
        z-index: 9999;
    }
    .main {
        .page-wrapper {
            padding: 30px 5px;
        }
    }
}