.card-block {
    padding: 1.25rem;
}

.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    border: none;
    margin-bottom: 30px;
    .card-footer {
        border-top: none;
        &.table-footer {
            background: transparent;
            // border-top: 1px solid rgba(0, 0, 0, .125);
        }
    }
    .card-header {
        background-color: transparent;
        border-bottom: none;
        padding: 25px 20px;
        .card-header-left {
            display: inline-block;
        }
        .card-header-right {
            border-radius: 0 0 0 7px;
            right: 10px;
            top: 18px;
            display: inline-block;
            float: right;
            padding: 7px 0;
            position: absolute;
            i {
                margin: 0 8px;
                cursor: pointer;
                font-size: 16px;
                color: #919aa3;
                line-height: 20px;
                &.icofont.icofont-spinner-alt-5 {
                    display: none;
                }
            }
            .card-option {
                -webkit-transition: 0.3s ease-in-out;
                transition: 0.3s ease-in-out;
                li {
                    display: inline-block;
                }
            }
        }
        > span {
            color: #919aa3;
            display: block;
            font-size: 13px;
            margin-top: 5px;
        }
        + {
            .card-block,
            .card-block-big {
                padding-top: 0;
            }
        }
        h5 {
            margin-bottom: 0;
            color: #505458;
            font-size: 14px;
            font-weight: 600;
            display: inline-block;
            margin-right: 10px;
            line-height: 1.4;
        }
    }
    .card-block {
        // table {
        //     tr {
        //         padding-bottom: 20px;
        //     }
        // }
        ul.pagination li {
            margin-right: 0;
        }
        .sub-title {
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 1px;
        }
        code {
            background-color: #eee;
            margin: 5px;
            display: inline-block;
        }
        .dropdown-menu {
            top: 38px;
        }
        p {
            line-height: 25px;
        }
        a.dropdown-item {
            margin-bottom: 0;
            font-size: 14px;
            -webkit-transition: 0.25s;
            transition: 0.25s;
            &:active,
            .active {
                background-color: #01a9ac;
            }
        }
        &.remove-label i {
            margin: 0;
            padding: 0;
        }
        &.button-list span.badge {
            margin-left: 5px;
        }
        .dropdown-menu {
            background-color: #fff;
            padding: 0;
            .dropdown-divider {
                background-color: #ddd;
                margin: 3px 0;
            }
            > {
                a {
                    padding: 10px 16px;
                    line-height: 1.429;
                }
                li {
                    > a {
                        &:focus,
                        &:hover {
                            background-color: rgba(202, 206, 209, 0.5);
                        }
                    }
                    &:first-child > a:first-child {
                        border-top-right-radius: 4px;
                        border-top-left-radius: 4px;
                    }
                }
            }
        }
        .badge-box {
            padding: 10px;
            margin: 12px 0;
        }
    }
    .card-block-big {
        padding: 30px 35px;
    }
    .card-block-small {
        padding: 15px 20px;
    }
    &.full-card {
        position: fixed;
        top: 56px;
        z-index: 99999;
        -webkit-box-shadow: none;
        box-shadow: none;
        left: 0;
        border-radius: 0;
        border: 1px solid #ddd;
        width: 100vw;
        height: calc(100vh - 56px);
        &.card-load {
            position: fixed;
        }
    }
    &.card-load {
        position: relative;
        overflow: hidden;
        .card-loader {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            background-color: rgba(255, 255, 255, 0.7);
            z-index: 999;
            i {
                margin: 0 auto;
                color: #ab7967;
                font-size: 20px;
            }
        }
    }
    .overflow-container {
        h5 {
            margin-bottom: 5px;
        }
    }
}

.card-header-text {
    margin-bottom: 0;
    font-size: 1rem;
    color: rgba(51, 51, 51, 0.85);
    font-weight: 600;
    display: inline-block;
    vertical-align: middle;
}

.update-card {
    color: #fff;
}

.update-card .card-footer {
    background-color: transparent;
    border-top: 1px solid #fff;
}

@media only screen and (max-width: 768px) {
    .card {
        h5 {
            font-size: 16px;
        }
    }
    .card-header-right i:nth-child(n + 2) {
        display: none;
    }
}

@media only screen and (max-width: 573px) {
    .card-block-big {
        padding: 1em;
    }
}
