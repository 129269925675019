.close {
    cursor: pointer;
    font-size: 16px;
    margin-top: 5px;
}

button {
    &:focus {
        outline: 0;
    }
}

.btn {
    border-radius: 2px;
    text-transform: capitalize;
    font-size: 15px;
    padding: 10px 19px;
    cursor: pointer;
    i {
        margin-right: 5px;
    }
    &.btn-round {
        border-radius: 2rem;
    }
    &.btn-square {
        border-radius: 0;
    }
    &.btn-icon {
        border-radius: 50%;
        width: 40px;
        line-height: 30px;
        height: 40px;
        padding: 3px;
        text-align: center;
    }
    &:hover,
    &:focus,
    &:active {
        outline: 0;
        box-shadow: none;
    }
}

.btn-group {
    display: inline-block;
    .btn {
        float: left;
    }
    .icon-btn {
        i {
            margin-right: 0 !important;
        }
    }
    [class*='btn-'] {
        margin-right: 0;
    }
}

.btn-primary {
    background-color: $primary;
    border-color: $primary;
    color: $white;
    cursor: pointer;
    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s;
    &:hover {
        background-color: #01dbdf;
        border-color: #01dbdf;
    }
    &:active {
        background-color: #016d6f !important;
        border-color: #016d6f;
        -webkit-box-shadow: none;
        box-shadow: none;
        color: $white;
    }
    &:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
        color: $white;
        background-color: #01dbdf;
    }
    &.disabled {
        background-color: rgba(1, 169, 172, 0.5);
        border-color: transparent;
    }
}

.btn-warning {
    background-color: $warning;
    border-color: $warning;
    color: $white;
    cursor: pointer;
    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s;
    &:hover {
        background-color: #feb798;
        border-color: #feb798;
        color: $white;
    }
    &:active {
        background-color: #fe6828 !important;
        border-color: #fe6828;
        -webkit-box-shadow: none;
        box-shadow: none;
        color: $white;
    }
    &:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
        color: $white;
        background-color: #feb798;
    }
    &.disabled {
        background-color: rgba(254, 147, 101, 0.5);
        border-color: rgba(254, 147, 101, 0.5);
    }
}

.btn-danger {
    background-color: $danger;
    border-color: $danger;
    color: $white;
    cursor: pointer;
    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s;
    &:hover {
        background-color: #fe909d;
        border-color: #fe909d;
    }
    &:active {
        background-color: #fe203a !important;
        border-color: #fe203a;
        -webkit-box-shadow: none;
        box-shadow: none;
        color: $white;
    }
    &:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
        color: $white;
        background-color: #fe909d;
    }
    &.disabled {
        background-color: rgba(254, 93, 112, 0.5);
        border-color: rgba(254, 93, 112, 0.5);
    }
}

.btn-success {
    background-color: $success;
    border-color: $success;
    color: $white;
    cursor: pointer;
    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s;
    &:hover {
        background-color: #0df3a3;
        border-color: #0df3a3;
    }
    &:active {
        background-color: #07885b !important;
        border-color: #07885b;
        -webkit-box-shadow: none;
        box-shadow: none;
        color: $white;
    }
    &:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
        color: $white;
        background-color: #0df3a3;
    }
    &.disabled {
        background-color: rgba(10, 194, 130, 0.5);
        border-color: rgba(10, 194, 130, 0.5);
    }
}

.btn-inverse {
    background-color: $inverse;
    border-color: $inverse;
    color: $white;
    cursor: pointer;
    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s;
    &:hover {
        background-color: #546686;
        border-color: #546686;
        color: $white;
    }
    &:active {
        background-color: #293141 !important;
        border-color: #293141;
        -webkit-box-shadow: none;
        box-shadow: none;
        color: $white;
    }
    &:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
        color: $white;
        background-color: #546686;
    }
    &.disabled {
        background-color: rgba(64, 78, 103, 0.5);
        border-color: transparent;
    }
}

.btn-info {
    background-color: $info;
    border-color: $info;
    color: $white;
    cursor: pointer;
    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s;
    &:hover {
        background-color: #5ad9e9;
        border-color: #5ad9e9;
    }
    &:active {
        background-color: #19a7ba !important;
        border-color: #19a7ba;
        -webkit-box-shadow: none;
        box-shadow: none;
        color: $white;
    }
    &:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
        color: $white;
        background-color: #5ad9e9;
    }
    &.disabled {
        background-color: rgba(45, 206, 227, 0.5);
        border-color: rgba(45, 206, 227, 0.5);
    }
}

.btn-disabled {
    background-color: rgba(135, 144, 159, 0.5);
    border-color: rgba(135, 144, 159, 0.5);
    color: $white;
    cursor: not-allowed;
    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s;
    &:hover {
        color: $white;
    }
}

.btn-outline-primary {
    color: #016d6f;
    background-color: $white;
    background-color: transparent;
    border-color: $primary;
    font-weight: 500;
    padding: 10px 16px;
    font-size: 15px;
    &:hover {
        background-color: $primary;
        border-color: $primary;
    }
}

.btn-outline-warning {
    color: #fe6828;
    background-color: $white;
    background-color: transparent;
}

.btn-outline-danger {
    color: #fe203a;
    background-color: $white;
    background-color: transparent;
}

.btn-outline-success {
    color: #07885b;
    background-color: $white;
    background-color: transparent;
}

.btn-outline-inverse {
    color: #293141;
    background-color: $white;
    background-color: transparent;
    &:hover {
        color: $white;
    }
}

.btn-outline-info {
    color: #19a7ba;
    background-color: $white;
    background-color: transparent;
}

.btn-outline-disabled {
    color: #677181;
    background-color: $white;
    background-color: #87909f;
    &:before {
        background-color: #677181;
    }
}

.btn-xlg {
    padding: 14px 20px;
    font-size: 21px;
    line-height: 40px;
}

.btn-lg {
    padding: 12px 18px;
    font-size: 18px;
    line-height: 32px;
}

.btn-md {
    padding: 10px 16px;
    font-size: 15px;
    line-height: 23px;
}

.btn-sm {
    padding: 8px 14px;
    line-height: 16px;
    font-size: 11px;
}

.btn-mini {
    padding: 5px 10px;
    line-height: 14px;
    font-size: 10px;
}

.btn-inline {
    margin-top: 20px;
    i {
        color: $white;
    }
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus {
    box-shadow: none;
}