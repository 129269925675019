/* Standard */

.form-group {
  margin-bottom: 1.25em;
  .form-control-lg {
    font-size: 1.25em;
  }
  .form-control-round {
    border-radius: 50px;
  }
  .messages {
    p {
      margin-bottom: 0;
      -webkit-transition: all ease-in 0.3s;
      transition: all ease-in 0.3s;
    }
  }
  .form-control-static {
    &:focus {
      outline: none;
    }
  }
}

.form-control {
  font-size: 14px;
  border-radius: 2px;
  border: 1px solid #ccc;
  &:focus {
    border: 1px solid $primary;
    box-shadow: none;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &.is-invalid {
    border-color: $danger;
    &:focus {
      box-shadow: none;
    }
  }
}

.invalid-feedback {
  color: $danger;
}

.input-group {
  .form-control {
    border-left: none;
  }
  &.right-button {
    .form-control {
      border-right: none;
      border-left: 1px solid #ccc;
      &:focus {
        border: 1px solid $primary;
        border-right: none;
      }
    }
  }
}

.input-group-text {
  background-color: $primary;
  color: #fff;
  border: none;
  font-size: 1rem;
}

/* Checkbox */

.checkbox,
.checkbox-fade,
.checkbox-zoom {
  display: inline-block;
  margin-right: 15px;
  .cr {
    border-radius: 0;
    border: 2px solid #01a9ac;
    cursor: pointer;
    display: inline-block;
    float: left;
    height: 20px;
    margin-right: 0.5em;
    position: relative;
    width: 20px;
    .cr-icon {
      color: #01a9ac;
      font-size: 0.8em;
      left: 0;
      line-height: 0;
      position: absolute;
      right: 0;
      text-align: center;
      top: 50%;
    }
  }
  label {
    margin-bottom: 0;
    line-height: 20px;
    user-select: none;
    &:after {
      content: '';
      display: table;
      clear: both;
    }
    input[type='checkbox'],
    input[type='radio'] {
      display: none;
      + .cr {
        > .cr-icon {
          -webkit-transform: scale3d(0.2, 0.2, 0.1) translate3d(0, 0, 0);
          transform: scale3d(0.2, 0.2, 0.1) translate3d(0, 0, 0);
          opacity: 0;
          -webkit-transition: all 0.3s ease-in;
          transition: all 0.3s ease-in;
        }
      }
      &:checked + .cr {
        > .cr-icon {
          -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
          transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
          opacity: 1;
        }
      }
    }
  }
  &.fade-in-inverse {
    .cr {
      border: 2px solid #404e67;
      .cr-icon {
        color: #404e67;
      }
    }
  }
}

.checkbox-zoom {
  label {
    input[type='checkbox'],
    input[type='radio'] {
      + .cr {
        > .cr-icon {
          -webkit-transform: scale3d(0.2, 0.2, 0.1) translate3d(0, 0, 0);
          transform: scale3d(0.2, 0.2, 0.1) translate3d(0, 0, 0);
          opacity: 0;
          -webkit-transition: all 0.3s ease-in;
          transition: all 0.3s ease-in;
        }
      }
      &:checked + .cr {
        > .cr-icon {
          -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
          transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
          opacity: 1;
        }
      }
    }
  }
}

.checkbox-fade {
  label {
    input[type='checkbox'],
    input[type='radio'] {
      + .cr {
        > .cr-icon {
          -webkit-transform: scale(3) rotateZ(-20deg);
          transform: scale(3) rotateZ(-20deg);
          opacity: 0;
          -webkit-transition: all 0.3s ease-in;
          transition: all 0.3s ease-in;
        }
      }
      &:checked + .cr {
        > .cr-icon {
          -webkit-transform: scale(1) rotateZ(0deg);
          transform: scale(1) rotateZ(0deg);
          opacity: 1;
        }
      }
    }
  }
}
.custom-select {
  border: none;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  padding-left: 0;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0IDI0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4uc3Qwe2ZpbGw6bm9uZTtzdHJva2U6IzQwNEU2NztzdHJva2Utd2lkdGg6MjtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbGluZWpvaW46cm91bmQ7fTwvc3R5bGU+PHBvbHlsaW5lIGNsYXNzPSJzdDAiIHBvaW50cz0iNiw5IDEyLDE1IDE4LDkgIi8+PC9zdmc+)
    no-repeat right 0px center/14px 14px;
  padding-right: 20px;
  color: #fff;
  &:focus {
    box-shadow: none;
    border-color: $primary;
  }

  & option {
    color: #404e67;
  }
}
