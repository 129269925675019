.table-bordered,
.table-bordered td,
.table-bordered th {
    border: 1px solid #e9ecef;
}

.table {
    &.fixed-table {
        table-layout: fixed;
    }
    button {
        margin-bottom: 0;
    }
    td,
    th {
        padding: 0.75rem;
    }
    thead {
        th {
            border-bottom: 2px solid #e9ecef;
        }
    }
}

@media (max-width: 768px) {
    .table-responsive {
        display: inline-block;
        width: 100%;
        overflow-x: auto;
        vertical-align: top;
        .table {
            table-layout: auto;
        }
    }
}

.table-primary,
.table-primary>td,
.table-primary>th {
    background-color: #01a9ac;
}

.table.table-xl td,
.table.table-xl th {
    padding: 1.25rem 2rem;
}

.table.table-lg td,
.table.table-lg th {
    padding: 0.9rem 2rem;
}

.table.table-de td,
.table.table-de th {
    padding: 0.75rem 2rem;
}

.table.table-sm td,
.table.table-sm th {
    padding: 0.6rem 2rem;
}

.table.table-xs td,
.table.table-xs th {
    padding: 0.4rem 2rem;
}

.table-columned>tbody>tr>td:first-child,
.table-columned>tbody>tr>th:first-child {
    border-left: 0;
}

.table-columned>tfoot>tr>td:first-child,
.table-columned>tfoot>tr>th:first-child {
    border-left: 0;
}

.table-columned>tbody>tr>td,
.table-columned>tbody>tr>th {
    border: 0;
    border-left: 1px solid #ddd;
}

.table-columned>tfoot>tr>td,
.table-columned>tfoot>tr>th {
    border: 0;
    border-left: 1px solid #ddd;
}

.table-border-style {
    padding: 0;
}

.table-border-style .table {
    margin-bottom: 0;
}

// .table>thead>tr>th {
//     border-bottom-color: #ccc
// }
.table-bordered tfoot td,
.table-bordered tfoot th {
    border-top-width: 2px;
}

// .table>tfoot>tr>th {
//     border-top-color: #ccc
// }
.table-borderless tbody tr td,
.table-borderless tbody tr th {
    border: 0;
}

.table-bordered>thead>tr.border-solid>td,
.table-bordered>thead>tr.border-solid>th {
    border-bottom-width: 2px;
}

.table-bordered>thead>tr.border-solid:first-child>td,
.table-bordered>thead>tr.border-solid:first-child th {
    border-bottom-width: 2px;
}

.table-bordered>thead>tr.border-double>td,
.table-bordered>thead>tr.border-double>th {
    border-bottom-width: 3px;
    border-bottom-style: double;
}

.table-bordered>thead>tr.border-double:first-child>td,
.table-bordered>thead>tr.border-double:first-child th {
    border-bottom-width: 3px;
    border-bottom-style: double;
}

th,
td {
    white-space: nowrap;
}

.table td {
    padding: 1rem 0.75rem;
}

.table td,
.table th {
    vertical-align: middle;
}

.table-header {
    margin-bottom: 10px;
    .table-top-nav {
        li {
            display: inline-block;
            margin: 0;
            padding: 0;
            white-space: nowrap;
            margin-right: 5px;
            padding-right: 5px;
            position: relative;
            line-height: 24px;
            &:after {
                content: '|';
                position: absolute;
                top: 0;
                right: -5px;
            }
            &:last-of-type {
                margin-right: 0;
                padding-right: 0;
                &:after {
                    display: none;
                }
            }
            a {
                display: block;
                &.current {
                    font-weight: 600;
                }
                .count {
                    color: #555d66;
                    font-weight: 400;
                }
            }
        }
        @media (max-width: 576px) {
            margin-bottom: 10px;
            text-align: center;
        }
    }
    .table-search {
        .input-group {
            margin: 0;
        }
    }
}

.table-actions {
    &:after {
        content: '';
        display: block;
        clear: both;
    }
    .bulk-actions {
        select.form-control {
            display: inline-block;
            width: auto;
            height: 34px;
            vertical-align: top;
        }
        .btn {
            vertical-align: top;
        }
    }
    .pagination {
        float: right;
        @media (max-width: 576px) {
            float: none;
        }
    }
    @media (max-width: 576px) {
        text-align: center;
    }
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f9f9f9;
}

.main-list-table {
    margin-bottom: 0;
    table-layout: fixed;
    .cb-select-item {
        text-align: center;
        >i {
            font-size: 20px;
            line-height: 1em;
            vertical-align: top;
            display: inline-block;
        }
        .checkbox-fade,
        .checkbox-zoom {
            margin: 0;
            vertical-align: top;
            label {
                margin: 0;
                vertical-align: top;
            }
            .cr {
                margin: 0;
            }
        }
    }
    .date-col {
        width: 15%;
    }
    .author-col,
    .actions-col {
        width: 15%;
    }
    .parent-col {
        width: 10%;
    }
    @media (max-width: 1600px) {
        .actions-col {
            width: 20%;
        }
    }
    @media (max-width: 1440px) {
        .date-col {
            width: auto;
        }
        .author-col,
        .actions-col,
        .parent-col {
            width: auto;
        }
    }
    th {
        font-weight: 400;
        user-select: none;
        &.cb-select-item {
            width: 45px;
        }
    }
    td {
        &.name-col {
            a {
                font-weight: 600;
            }
            .post-state {
                font-weight: 600;
                color: #919aa3;
            }
        }
        &.actions-col {
            .org-actions {
                display: flex;
                justify-content: flex-start;
                &:after {
                    content: '';
                    display: block;
                    clear: both;
                }
                .action-link {
                    flex: 1;
                    position: relative;
                    margin-right: 5px;
                    padding-right: 5px;
                    &:after {
                        content: '|';
                        position: absolute;
                        top: 0;
                        right: -5px;
                        color: #404e67;
                    }
                    &:last-child {
                        margin: 0;
                        padding: 0;
                        &:after {
                            display: none;
                        }
                    }
                    i {
                        margin-right: 2px;
                    }
                    @media(max-width: 1440px) {
                        >span {
                            display: none;
                        }
                    }
                }
                .trash {
                    color: red;
                    &:hover {
                        opacity: 0.65;
                    }
                }
            }
        }
    }
    @media (max-width: 1280px) {
        table-layout: auto;
    }
}

.info-table {
    .name-col {
        width: 40%;
    }
    td {
        &.name-col {
            a {
                font-weight: 600;
            }
            .post-state {
                font-weight: 600;
                color: #919aa3;
            }
        }
        &.actions-col {
            .org-actions {
                a {
                    &.trash {
                        color: red;
                    }
                }
            }
        }
    }
}

dl.user-info {
    dt,
    dd {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        border-bottom: 1px solid #e9ecef;
        margin: 0;
    }
}