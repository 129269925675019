/* Preloaders */

.theme-loader {
    height: 100%;
    width: 100%;
    background-color: #fff;
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    .ball-scale {
        left: 50%;
        top: 50%;
        position: absolute;
        height: 50px;
        width: 50px;
        margin: -25px 0 0 -25px;
        .contain {
            height: 100%;
            width: 100%;
            .ring {
                display: none;
                &:first-child {
                    display: block;
                    height: 100%;
                    width: 100%;
                    border-radius: 50%;
                    padding: 10px;
                    border: 3px solid transparent;
                    border-left-color: #01a9ac;
                    border-right-color: #01a9ac;
                    -webkit-animation: round-rotate 1.5s ease-in-out infinite;
                    animation: round-rotate 1.5s ease-in-out infinite;
                    .frame {
                        height: 100%;
                        width: 100%;
                        border-radius: 50%;
                        border: 3px solid transparent;
                        border-left-color: #0ac282;
                        border-right-color: #0ac282;
                        -moz-animation: round-rotate 1.5s ease-in-out infinite;
                        -webkit-animation: round-rotate 1.5s ease-in-out infinite;
                        animation: round-rotate 1.5s ease-in-out infinite;
                    }
                }
            }
        }
    }
}

@-moz-keyframes round-rotate {
    100% {
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes round-rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes round-rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.preloader {
    width: 30px;
    height: 30px;
    position: relative;
    margin: 0 auto;
}

.double-bounce1,
.double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $primary;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: bounce 2s infinite ease-in-out;
    animation: bounce 2s infinite ease-in-out;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

@-webkit-keyframes bounce {
    0%,
    100% {
        -webkit-transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
    }
}

@keyframes bounce {
    0%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }
    50% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}