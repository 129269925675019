.sidebar {
    .nav-title {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1px;
        padding: 25px 20px 8px;
        text-transform: capitalize;
        border-bottom: 2px solid transparent;
        color: #999;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .nav {
        list-style: none;
    }
    .nav-item {
        position: relative;
        transition: none;
    }
    .nav-dropdown-items {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        transform: rotateX(-90deg);
        .nav-link {
            padding: 11px 15px;
            padding-left: 30px;
            &.active {
                color: $orange;
                .nav-icon {
                    color: $orange;
                }
            }
        }
    }

    .nav-dropdown {
        &.open {
            background: none;
            .nav-link {
                color: #dcdcdc;
                &:after {
                    content: ' ';
                    position: absolute;
                    top: 0;
                    left: 0;
                    border-left: 3px solid #fe8a7d;
                    height: 100%;
                }
                &.active {
                    color: $orange;
                }
                &:hover {
                    color: $orange;
                    .nav-icon {
                        color: $orange;
                    }
                }
            }
            > .nav-link {
                color: #dcdcdc;
                &:after {
                    display: none;
                }

                &:hover {
                    color: $white;
                    .nav-icon {
                        color: $white;
                    }
                }
            }
            > .nav-dropdown-items {
                position: relative;
                opacity: 1;
                visibility: visible;
                transform: rotateX(0deg);
                transform-origin: 10% 10%;
                transition: transform 0.5s, opacity 0.5s;
            }
            > .nav-dropdown-toggle {
                &:before {
                    transform: rotate(90deg) translate(-50%, 0%);
                }
            }
        }
    }
    .nav-link {
        display: block;
        font-size: 14px;
        line-height: 16px;
        text-decoration: none;
        position: relative;
        transition: all 0.3s ease-in-out;
        text-align: left;
        padding: 14px 15px;
        color: #dcdcdc;
        user-select: none;
        .nav-icon {
            color: #c3c3c3;
            font-size: 16px;
            width: auto;
            margin-right: 10px;
            line-height: 1em;
            vertical-align: top;
            transition: all 0.3s ease-in-out;
        }
        &:hover {
            background: none;
            color: $white;
            &.nav-dropdown-toggle {
                &:before {
                    background: none;
                }
            }
        }
        &.active {
            background: none;
            font-weight: 600;
        }
    }
    .nav-dropdown-toggle {
        &:before {
            content: '\e844';
            font-style: normal;
            font-family: 'feather';
            font-size: 14px;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            padding: 0;
            -webkit-transition: all 0.1s ease-in-out;
            transition: all 0.1s ease-in-out;
            color: #dcdcdc;
            background: none;
            margin: 0;
            width: auto;
            height: auto;
        }
    }
}
