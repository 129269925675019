.pagination {
    li {
        display: inline-block;
    }
}

.page-link {
    color: $body;
    &:hover {
        color: $primary;
    }
}

.page-item {
    &.active {
        .page-link {
            background-color: $primary;
            border-color: $primary;
        }
    }
}

@media only screen and (max-width: 1199px) {
    .pagination {
        float: inherit;
        text-align: center;
        display: inline-block;
        margin-top: 10px !important;
        li {
            float: left;
            margin-bottom: 0;
        }
    }
}

@media only screen and (max-width: 573px) {
    .pagination-lg {
        .page-link {
            padding: 0.75rem 0.65rem;
        }
    }
}

.page-link:focus {
    box-shadow: none;
}