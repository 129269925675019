// Variable overrides
$primary-font: 'Open Sans', sans-serif;

/* COLOR SCHEME */

$body: #353c4e;
$primary: #01a9ac;
$success: #0ac282;
$info: #2dcee3;
$warning: #fe9365;
$danger: #fe5d70;
$inverse: #404e67;
$disabled: #87909f;
$white: #fff;
$muted: #919aa3;

$orange: #fe8a7d;
