.theme-editor {
  .options-group {
    color: $primary;
  }
  .theme-option {
    margin-bottom: map_get($spacers, 3);
    label {
      margin-bottom: 0;
    }
  }
  .option-color, .option-emoji {
    .input-group {
      input {
        border: 1px solid $input-border-color;
      }
    }
  }
  .option-color {
    .color-square {
      border: 1px solid $input-border-color;
      cursor: pointer;
    }
  }
  .option-emoji {
    .emoji-square {
      border: 1px solid $input-border-color;
      cursor: pointer;
      background-color: transparent;
    }
    .input-group {
      .input-group-text {
        padding: 0;
      }
      input {
        background-color: $input-bg;
      }
    }
  }
  .option-text {
    input {
      border: 1px solid $input-border-color;
    }
  }
}


.themes-list {
  @keyframes highlight-theme-item-fade {
    from { background: $info; }
    to { background: none; }
  }

  .highlight-theme-item-fade {
    animation: highlight-theme-item-fade 5s ease-in;
  }
}


.inline-theme-preview {
  .preview-item {
    width: 24px;
    height: 24px;

    .option-color {
      display: inline-block;
      border: 1px solid transparent;
      border-radius: 9px;
      width: 18px;
      height: 18px
    }
    /*$input-border-color*/
  }
}
