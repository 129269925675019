/* Paddings */

.p-0 {
    padding: 0px;
}

.p-5 {
    padding: 5px;
}

.p-10 {
    padding: 10px;
}

.p-15 {
    padding: 15px;
}

.p-20 {
    padding: 20px;
}

.p-25 {
    padding: 25px;
}

.p-30 {
    padding: 30px;
}

.p-35 {
    padding: 35px;
}

.p-40 {
    padding: 40px;
}

.p-45 {
    padding: 45px;
}

.p-50 {
    padding: 50px;
}

.p-t-0 {
    padding-top: 0px !important;
}

.p-t-5 {
    padding-top: 5px !important;
}

.p-t-10 {
    padding-top: 10px !important;
}

.p-t-15 {
    padding-top: 15px !important;
}

.p-t-20 {
    padding-top: 20px !important;
}

.p-t-25 {
    padding-top: 25px !important;
}

.p-t-30 {
    padding-top: 30px !important;
}

.p-t-35 {
    padding-top: 35px !important;
}

.p-t-40 {
    padding-top: 40px !important;
}

.p-t-45 {
    padding-top: 45px !important;
}

.p-t-50 {
    padding-top: 50px !important;
}

.p-b-0 {
    padding-bottom: 0px !important;
}

.p-b-5 {
    padding-bottom: 5px !important;
}

.p-b-10 {
    padding-bottom: 10px !important;
}

.p-b-15 {
    padding-bottom: 15px !important;
}

.p-b-20 {
    padding-bottom: 20px !important;
}

.p-b-25 {
    padding-bottom: 25px !important;
}

.p-b-30 {
    padding-bottom: 30px !important;
}

.p-b-35 {
    padding-bottom: 35px !important;
}

.p-b-40 {
    padding-bottom: 40px !important;
}

.p-b-45 {
    padding-bottom: 45px !important;
}

.p-b-50 {
    padding-bottom: 50px !important;
}

.p-l-0 {
    padding-left: 0px;
}

.p-l-5 {
    padding-left: 5px;
}

.p-l-10 {
    padding-left: 10px;
}

.p-l-15 {
    padding-left: 15px;
}

.p-l-20 {
    padding-left: 20px;
}

.p-l-25 {
    padding-left: 25px;
}

.p-l-30 {
    padding-left: 30px;
}

.p-l-35 {
    padding-left: 35px;
}

.p-l-40 {
    padding-left: 40px;
}

.p-l-45 {
    padding-left: 45px;
}

.p-l-50 {
    padding-left: 50px;
}

.p-r-0 {
    padding-right: 0px;
}

.p-r-5 {
    padding-right: 5px;
}

.p-r-10 {
    padding-right: 10px;
}

.p-r-15 {
    padding-right: 15px;
}

.p-r-20 {
    padding-right: 20px;
}

.p-r-25 {
    padding-right: 25px;
}

.p-r-30 {
    padding-right: 30px;
}

.p-r-35 {
    padding-right: 35px;
}

.p-r-40 {
    padding-right: 40px;
}

.p-r-45 {
    padding-right: 45px;
}

.p-r-50 {
    padding-right: 50px;
}

/* Margins */

.m-0 {
    margin: 0px;
}

.m-5 {
    margin: 5px;
}

.m-10 {
    margin: 10px;
}

.m-15 {
    margin: 15px;
}

.m-20 {
    margin: 20px;
}

.m-25 {
    margin: 25px;
}

.m-30 {
    margin: 30px;
}

.m-35 {
    margin: 35px;
}

.m-40 {
    margin: 40px;
}

.m-45 {
    margin: 45px;
}

.m-50 {
    margin: 50px;
}

.m-t-0 {
    margin-top: 0px;
}

.m-t-5 {
    margin-top: 5px;
}

.m-t-10 {
    margin-top: 10px;
}

.m-t-15 {
    margin-top: 15px;
}

.m-t-20 {
    margin-top: 20px;
}

.m-t-25 {
    margin-top: 25px;
}

.m-t-30 {
    margin-top: 30px;
}

.m-t-35 {
    margin-top: 35px;
}

.m-t-40 {
    margin-top: 40px;
}

.m-t-45 {
    margin-top: 45px;
}

.m-t-50 {
    margin-top: 50px;
}

.m-b-0 {
    margin-bottom: 0px;
}

.m-b-5 {
    margin-bottom: 5px;
}

.m-b-10 {
    margin-bottom: 10px;
}

.m-b-15 {
    margin-bottom: 15px;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-b-25 {
    margin-bottom: 25px;
}

.m-b-30 {
    margin-bottom: 30px;
}

.m-b-35 {
    margin-bottom: 35px;
}

.m-b-40 {
    margin-bottom: 40px;
}

.m-b-45 {
    margin-bottom: 45px;
}

.m-b-50 {
    margin-bottom: 50px;
}

.m-l-0 {
    margin-left: 0px;
}

.m-l-5 {
    margin-left: 5px;
}

.m-l-10 {
    margin-left: 10px;
}

.m-l-15 {
    margin-left: 15px;
}

.m-l-20 {
    margin-left: 20px;
}

.m-l-25 {
    margin-left: 25px;
}

.m-l-30 {
    margin-left: 30px;
}

.m-l-35 {
    margin-left: 35px;
}

.m-l-40 {
    margin-left: 40px;
}

.m-l-45 {
    margin-left: 45px;
}

.m-l-50 {
    margin-left: 50px;
}

.m-r-0 {
    margin-right: 0px;
}

.m-r-5 {
    margin-right: 5px;
}

.m-r-10 {
    margin-right: 10px;
}

.m-r-15 {
    margin-right: 15px;
}

.m-r-20 {
    margin-right: 20px;
}

.m-r-25 {
    margin-right: 25px;
}

.m-r-30 {
    margin-right: 30px;
}

.m-r-35 {
    margin-right: 35px;
}

.m-r-40 {
    margin-right: 40px;
}

.m-r-45 {
    margin-right: 45px;
}

.m-r-50 {
    margin-right: 50px;
}

/* Font Weights */

.f-w-100 {
    font-weight: 100;
}

.f-w-300 {
    font-weight: 300;
}

.f-w-400 {
    font-weight: 400;
}

.f-w-600 {
    font-weight: 600;
}

.f-w-700 {
    font-weight: 700;
}

.f-w-900 {
    font-weight: 900;
}

/* Floats */
.f-left {
    float: left;
}

.f-right {
    float: right;
}

.f-none {
    float: none;
}

/* Text Alignment */

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-lowercase {
    text-transform: lowercase;
}

/* Text Colors */

.text-primary {
    color: $primary !important;
}

.text-warning {
    color: $warning !important;
}

.text-default {
    color: $body !important;
}

.text-danger {
    color: $danger !important;
}

.text-success {
    color: $success !important;
}

.text-inverse {
    color: $inverse !important;
}

.text-info {
    color: $info !important;
}

.text-muted {
    color: $muted !important;
}
