// Here you can add other styles
.modal-content {
    border: none;
}

.modal-success {
    .modal-content {
        border: none;
    }
}

.close {
    color: $white;
    opacity: 1;
    font-size: 22px;
}

.modal-title {
    font-weight: 600;
}

.react-datepicker {
    min-width: 330px;
}

.react-datepicker__time-container {
    float: left;
}

@media(max-width:768px) {
    .custom-date-range-picker {
        display: block;
        .react-datepicker-wrapper {
            &:first-of-type {
                margin-right: 0;
                margin-bottom: 1.25em;
            }
        }
    }
}

@media(max-width:640px) {
    .p-4 {
        padding: 0 !important;
    }
}