.custom-date-range-picker {
    display: flex;
    align-items: center;
    .react-datepicker-wrapper {
        flex-grow: 1;
        &:first-of-type {
            margin-right: 10px;
        }
    }
}

.react-datepicker-wrapper {
    display: block;
    .react-datepicker__input-container {
        display: block;
    }
}

.form-group.is-invalid {
    & .invalid-feedback {
        display: block;
    }
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    padding: 5px 0px;
    font-size: 12px;
}

.react-datepicker {
    border: none;
    font-family: 'Open Sans', sans-serif;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    background: transparent;
}

.react-datepicker__header {
    background-color: #01a9ac;
    border-bottom: none;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    color: $white;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-bottom-color: #01a9ac;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    border-top-color: #fff;
}

.react-datepicker__navigation--previous {
    border-right-color: rgba($white, 0.7);
    &:hover {
        border-right-color: rgba($white, 1);
    }
}

.react-datepicker__navigation--next {
    border-left-color: rgba($white, 0.7);
    &:hover {
        border-left-color: rgba($white, 1);
    }
}

.react-datepicker__day-name {
    color: $white;
}

.react-datepicker__time-container {
    border-left: 1px solid #efefef;
}

.react-datepicker__month {
    background: $white;
    margin: 0;
    padding: 0.4rem;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
    background-color: #01a9ac;
    &:hover {
        background-color: #01a9ac;
    }
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
    background-color: #01a9ac;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #01a9ac;
    &:hover {
        background-color: #01a9ac;
    }
}