@keyframes shake {
    0% {
        transform: rotate(0deg);
    }
    20% {
        transform: rotate(5deg);
    }
    40% {
        transform: rotate(-5deg);
    }
    60% {
        transform: rotate(5deg);
    }
    80% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes pop-in {
    0% {
        transform: scale(0.5, 0.5);
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    65% {
        transform: scale(1.1, 1.1);
    }
    100% {
        transform: scale(1, 1);
    }
}

@keyframes pop-away {
    0% {
        transform: scale(1, 1) translate(0, 0);
    }
    40% {
        transform: scale(1.1, 1.1);
    }
    65% {
        opacity: 1;
    }
    99% {
        transform: scale(0.5, 0.5);
        opacity: 0;
    }
    100% {
        transform: translate(-10000em, -10000em);
    }
}

.center-child {
    background-color: #1c1b1a;
    background-image: linear-gradient(90deg, transparent 50%, #333 50%);
    background-size: 5px 5px;
    background-repeat: repeat;
    width: 100%;
    height: 100%;
}

.memory-app {
    position: relative;
    margin: 0 auto;
    max-width: 400px;
}

.center-child {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.cards-mm {
    display: flex;
    flex-directon: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.card-mm {
    width: 80px;
    height: 120px;
    text-align: center;
    transition: all 0.2s;
    perspective: 800;
    margin-left: 20px;
    margin-bottom: 20px;
}

.card--removed {
    animation-name: shake;
    animation-duration: 0.3s;
}

.card--selected {
    //transform: rotate(20deg);
}

.card--selected .card__inner,
.card--removed .card__inner {
    transform: rotatey(-180deg);
}

.card--unselected .card__front {
    cursor: pointer;
    transition: box-shadow 0.25s;
    &:hover {
        // The following box-shadow is overriden
        // by the "style" property in the Card component
        box-shadow: 0 0 20px #ffcc00;
    }
    &:active {
        //transform: scale(.97);
    }
}

.card__inner {
    //background-color: white;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: all 0.5s;
}

.card__face {
    // backface-visibility: hidden ;
    width: 100%;
    height: 100%;
    background-color: pink;
    //border: 2px solid transparent;
    position: absolute;
    border-radius: 5%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card__front {
    // The following background gradient is overriden
    // by the "style" property in the Card component
    background: repeating-linear-gradient( 45deg, #606dbc, #606dbc 10px, #465298 10px, #465298 20px);
    overflow: hidden;
    z-index: 1;
    &::before {
        background: repeating-linear-gradient( 310deg, black, black 10px, white 10px, white 20px);
        width: 100%;
        height: 100%;
        opacity: 0.1;
        content: '';
    }
}

.card__back {
    z-index: 2;
    transform: rotatey(-180deg);
    &:before {
        content: '';
        font-size: 50px;
        display: block;
    }
    &:after {
        content: '';
        font-size: 3vw;
        color: #fff;
        display: block;
        //margin-top: -1vw;
    }
}

@media (max-width:576px) {
    .cards-mm {
        justify-content: space-evenly;
    }
    .card-mm {
        margin: 0 10px 10px;
    }
}

@media (max-width:480px) {
    .card-mm {
        margin: 0 0 10px 0;
    }
}
