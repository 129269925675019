body {
    background-color: #f3f3f3;
    font-size: 0.875em;
    overflow-x: hidden;
    color: $body;
    font-family: $primary-font;
    background-attachment: fixed;
}

img {
    max-width: 100%;
    height: auto;
}

ul {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
}

*:focus {
    outline: none;
}

a {
    font-size: 13px;
    color: $inverse;
}

@media only screen and (min-width: 1400px) {
    a {
        font-size: 14px;
    }
}

a:focus {
    text-decoration: none;
    outline: 0;
}

a:hover {
    text-decoration: none;
    color: $primary;
}

span.link {
    cursor: pointer;
    font-size: 13px;
    color: $inverse;
    &:hover {
        color: $primary;
    }
}

@media only screen and (min-width: 1400px) {
    span.link {
        font-size: 14px;
    }
}

h6 {
    font-size: 14px;
}

h5 {
    margin: 0;
}

p {
    font-size: 13px;
}

@media only screen and (min-width: 1400px) {
    p {
        font-size: 14px;
    }
}

.footer {
    background-color: $inverse;
    color: #fff;
    padding: 15px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    p {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 768px) {
    h4 {
        font-size: 1rem;
        font-weight: 600;
    }
}

@media (max-width:640px) {
    h1,
    .h1 {
        font-size: 30px;
    }
}